import styled from "styled-components";
import { Modal } from "antd";

// Base Alert

export const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-content {
      padding: 30px 20px;
    }
    .ant-modal-body {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      span,
      div {
        z-index: 2;
      }
    }
  }
`;

export const BackgroundImg = styled.img`
  position: absolute;
  height: 300px;
  width: 300px;
  z-index: 1;
  opacity: 0.03;
`;

export const ButtonRows = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  gap: 30px;
  > button {
    width: 50%;
  }
`;

export const Title = styled.span`
  color: ${({ color }) => color};
  font-size: 22px;
  font-weight: 500;
  align-self: center;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.black};
  white-space: pre-wrap;
  line-height: 1.5;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
`;

export const Icon = styled.img`
  width: 110px;
  height: auto;
  align-self: center;
`;

export const Divider = styled.div`
  background-color: ${({ color }) => color};
  width: 100%;
  height: 1px;
`;

// Error Message

export const ErrorMessageRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-left: ${({ theme }) => `3px solid ${theme.danger}`};
  padding: 5px 10px;
  background-color: #f0f0f0;
  width: 100%;
  box-sizing: border-box;
`;
