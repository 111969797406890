import successIcon from "assets/icons/success.png";
import warningIcon from "assets/icons/warning.png";
import errorIcon from "assets/icons/error.png";
import infoIcon from "assets/icons/info.png";

function typeAttribute(type) {
  switch (type) {
    case "error": {
      return {
        icon: errorIcon,
        color: "danger",
      };
    }

    case "warning": {
      return {
        icon: warningIcon,
        color: "warning",
      };
    }

    case "info": {
      return {
        icon: infoIcon,
        color: "info",
      };
    }

    case "success":
    default: {
      return {
        icon: successIcon,
        color: "textSuccess",
      };
    }
  }
}

const TOAST_TIMEOUT = 3000;
const TRANSACTION_CONFIG = { tension: 125, friction: 20, precision: 0.1 };

export { typeAttribute, TOAST_TIMEOUT, TRANSACTION_CONFIG };
