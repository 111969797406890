import BaseModel from "base/BaseModel";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { uniqueId } from "lodash";

const alertTypes = [
  // variants
  "success",
  "error",
  "warning",
  "info",
  "delete",
  "unsaved",
  "customize",

  // functions
  "close",
  "showValidationMessage",
];
const toastTypes = [
  "success",
  "error",
  "warning",
  "info",

  // functions
  "closeAll",
];

const usePopUpModel = (savedModelInstance = {}) => {
  // Region External Hooks

  const theme = useTheme();
  const { t, i18n } = useTranslation();

  // End Region

  // Region State, Memos, Refs

  const [open, setOpen] = useState(false);
  const [props, setProps] = useState({});
  const [toastList, setToastList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // End Region

  // Region Functions and Callbacks

  const changeIsLoading = useCallback((e) => {
    setIsLoading(e);
  }, []);

  const changeInputValue = useCallback((e) => {
    setInputValue(e);
  }, []);

  const changeValidationMessage = useCallback((e) => {
    setValidationMessage(e);
  }, []);

  const toggleToast = useCallback((id, title, text, inputType) => {
    if (id === "closeAll") {
      setToastList([]);
      return;
    }
    if (!id) {
      setToastList((prev) => [
        ...prev,
        { title, text, id: uniqueId(), type: inputType },
      ]);
      return;
    }

    setToastList((prev) => prev.filter((v) => v.id !== id));
  }, []);

  function toggleAlert(alertProps, type) {
    if (alertProps) {
      setOpen(true);
      setProps({ ...alertProps, type });
      return;
    }

    setOpen(false);
    setProps({});
    changeInputValue("");
    changeValidationMessage("");
  }

  const TOAST = toastTypes.reduce((acc, type) => {
    if (type === "closeAll") {
      return {
        ...acc,
        [type]: () => toggleToast("closeAll"),
      };
    }
    return {
      ...acc,
      [type]: (title, text) => toggleToast(null, title, text, type),
    };
  }, {});

  const ALERT = alertTypes.reduce((acc, type) => {
    if (type === "close") {
      return {
        ...acc,
        [type]: () => toggleAlert(null),
      };
    }

    if (type === "showValidationMessage") {
      return {
        ...acc,
        [type]: (e) => changeValidationMessage(e),
      };
    }

    return {
      ...acc,
      [type]: (e) => toggleAlert(e, type),
    };
  }, {});

  // End Region

  return {
    theme,
    t,
    i18n,

    open,
    props,
    toastList,
    inputValue,
    validationMessage,
    isLoading,

    changeIsLoading,
    changeInputValue,
    changeValidationMessage,
    toggleToast,
    toggleAlert,
    TOAST,
    ALERT,
  };
};

const PopUpModel = BaseModel(usePopUpModel, "PopUpModel");

export { usePopUpModel };
export default PopUpModel;
