import { useCallback, useState } from "react";
import BaseModel from "base/BaseModel";
import useWindowSize from "hooks/windowSizeHook";

const useAppUIModel = (savedModelInstance = {}) => {
  // Region External Hooks

  const { width: windowWidth } = useWindowSize();

  // End Region

  // Region State, Memos, Refs

  const [menuWidth] = useState(300);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [showMenu, setShowMenu] = useState(!(windowWidth <= 980));

  // End Region

  // Region Functions and Callbacks

  const toggleMenu = useCallback((state) => {
    setShowMenu(state);
  }, []);

  // End Region

  return {
    windowWidth,
    menuWidth,
    headerHeight,
    setHeaderHeight,
    showMenu,
    setShowMenu,
    toggleMenu,
  };
};

const AppUIModel = BaseModel(useAppUIModel, "AppUIModel");

export { useAppUIModel };
export default AppUIModel;
