import React, { useCallback, useContext, useMemo, useState } from "react";
import PopUpModel from "models/popUpModel";
import { StyledP } from "base/BaseStyle";
import {
  Icon,
  Title,
  Description,
  Divider,
  StyledModal,
  ButtonRows,
  BackgroundImg,
} from "../Alert.styles";
import Inputs from "../components/Inputs";
import CustomButton from "../../../CustomButton";
import { typeAttribute } from "../constants";

export default function BaseAlert() {
  const {
    theme,
    t,
    i18n,
    props,
    open,
    inputValue,
    changeInputValue,
    validationMessage,
    changeValidationMessage,
    isLoading,
    changeIsLoading,
    toggleAlert,
  } = useContext(PopUpModel.Context);

  const {
    type,
    inputType,
    closeText = "",
    confirmText = "",
    onClose = () => {},
    onConfirm = () => {},
    onClickLeaveWithoutSave = () => {},
    onError = () => {},
    showCloseButton = true,
    showConfirmButton = true,
    closeAfterConfirm = true,
    confirmButtonStyle,
    title = "",
    text = "",
    icon,
    remarks = "",
    footer = null,
    render,
    descriptionStyle,
    allowOutsideClick = true,
  } = props || {};

  const [alertState, setAlertState] = useState();

  const changeAlertState = useCallback((value) => setAlertState(value), []);

  async function asyncConfirm() {
    if (isLoading) {
      return;
    }

    const functionName = onConfirm?.constructor?.name;
    const outputValue = inputType ? inputValue : alertState;

    if (functionName === "AsyncFunction") {
      try {
        changeIsLoading(true);

        const response = await onConfirm(outputValue);

        if (typeof response === "boolean" && !response) {
          throw response;
        }

        changeIsLoading(false);
        changeAlertState();

        if (!closeAfterConfirm || (typeof response === "boolean" && response)) {
          return;
        }

        toggleAlert(null);
      } catch (error) {
        changeIsLoading(false);
        if (error) onError(error);
      }
      return;
    }

    onConfirm(outputValue);
    toggleAlert(null);
    changeAlertState();
  }

  async function asyncClose() {
    if (isLoading) {
      return;
    }
    try {
      await onClose();
      toggleAlert(null);
      changeAlertState();
    } catch (error) {
      console.debug(error);
    }
  }

  const defaultAttribute = useMemo(
    () => typeAttribute(type, theme, t),
    [type, i18n.language],
  );

  return (
    <StyledModal
      open={open}
      maskClosable={allowOutsideClick}
      onCancel={() => {
        if (isLoading) {
          return;
        }

        asyncClose();
      }}
      footer={null}
      closeIcon={false}
      centered
      width={420}
      zIndex="9999"
    >
      <BackgroundImg src={icon || defaultAttribute?.icon} alt="bg" />
      {(icon || defaultAttribute?.icon) && (
        <Icon src={icon || defaultAttribute?.icon} alt="alert" />
      )}
      <Title color={defaultAttribute?.color}>
        {title || defaultAttribute.title}
      </Title>
      {(text || defaultAttribute.text) && (
        <>
          <Divider color={defaultAttribute?.color} />
          <Description style={descriptionStyle}>
            {text || defaultAttribute.text}
          </Description>
        </>
      )}

      {render && React.cloneElement(render, { changeAlertState, alertState })}

      {inputType && (
        <Inputs
          type={inputType}
          value={inputValue}
          changeInputValue={changeInputValue}
          validationMessage={validationMessage}
          changeValidationMessage={changeValidationMessage}
          asyncConfirm={asyncConfirm}
        />
      )}

      {remarks && (
        <StyledP color="gray" fontSize="14" style={{ whiteSpace: "pre" }}>
          {remarks}
        </StyledP>
      )}

      <ButtonRows>
        {showCloseButton && (
          <CustomButton
            $bgColor="secondary"
            color="primary"
            onClick={asyncClose}
          >
            {closeText || defaultAttribute.closeText}
          </CustomButton>
        )}

        {showConfirmButton && (
          <CustomButton onClick={asyncConfirm} style={confirmButtonStyle}>
            {confirmText || defaultAttribute.confirmText}
          </CustomButton>
        )}
      </ButtonRows>

      {footer ||
        (type === "unsaved" && (
          <CustomButton
            variant="text"
            color="primary"
            onClick={() => {
              onClickLeaveWithoutSave();
              toggleAlert(null);
              changeAlertState();
            }}
          >
            Leave without saving
          </CustomButton>
        ))}
    </StyledModal>
  );
}
