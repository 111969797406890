import React, { Suspense, useContext, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import Spinner from "components/Spinner";
import UserModel from "models/userModel";
import { getGrantedRoutes } from "constants/components";
import NotFound from "views/NotFound";
import PrivateRoute from "base/PrivateRoute";
import { BindModels } from "models";
import Layout from "components/Layout";
import Popup from "components/Popup";
import DefaultRoute from "base/DefaultRoute";
import "./App.css";

const Auth = React.lazy(() => import("views/Auth"));

function App() {
  const { isLoggedIn, grantedMenuItems } = useContext(UserModel.Context);

  const routes = useMemo(
    () => getGrantedRoutes(grantedMenuItems),
    [grantedMenuItems],
  );

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        {isLoggedIn ? (
          <Route element={<Layout />}>
            <Route path="/" element={<DefaultRoute routes={routes} />} />

            {routes.map(({ path, component }) => (
              <Route
                key={path}
                path={path}
                element={<PrivateRoute component={component} />}
              />
            ))}

            <Route path="*" element={<NotFound />} />
          </Route>
        ) : (
          <Route path="*" element={<Auth />} />
        )}
      </Routes>
      <Popup />
    </Suspense>
  );
}

export default BindModels(App);
