import styled from "styled-components";

export const StyledP = styled.p`
  color: ${(props) =>
    props.theme[props.color]
      ? `${props.theme[props.color]}`
      : props.theme.black};
  font-size: ${({ fontSize }) => fontSize && `${fontSize}px`};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  margin: ${({ $noMargin, margin }) => ($noMargin ? 0 : margin)};
  text-align: ${({ $textAlign }) => $textAlign};
  white-space: ${({ $whiteSpace }) => $whiteSpace};
  font-family: ${({ theme }) => theme.typography.fontFamily};

  @media (max-width: 980px) {
    font-size: ${({ sx }) => sx?.fontSize && `${sx?.fontSize}px`};
    margin: ${({ sx }) => (sx?.noMargin ? 0 : sx?.margin)};
    text-align: ${({ sx }) => sx?.textAlign};
  }
`;

export const StyledSpan = styled.span`
  color: ${(props) =>
    props.theme[props.color]
      ? `${props.theme[props.color]}`
      : props.theme.black};
  font-size: ${({ fontSize }) => fontSize && `${fontSize}px`};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  margin: ${({ margin }) => margin};

  @media (max-width: 980px) {
    font-size: ${({ sx }) => `${sx?.fontSize}px`};
    margin: ${({ sx }) => sx?.margin};
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  margin: ${({ margin }) => margin};
  padding: ${({ $padding }) => $padding};
  align-items: ${({ $alignItems }) => $alignItems || "center"};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  gap: ${({ $gap }) => $gap && `${$gap}px`};

  @media (max-width: 980px) {
    font-size: ${({ sx }) => sx?.fontSize};
    margin: ${({ sx }) => sx?.margin};
    padding: ${({ sx }) => sx?.padding};
    justify-content: ${({ sx }) => sx?.justifyContent};
    align-items: ${({ sx }) => sx?.alignItems};
    flex-direction: ${({ sx }) => sx?.flexDirection};
  }
`;

export const Divider = styled.div`
  background-color: ${({ theme, color }) => theme[color] || theme.gray};
  width: 100%;
  height: 1px;
  margin: ${({ margin = "10px 0" }) => margin};
`;

export const StyledCard = styled.div`
  border-radius: 10px;
  background-color: ${({ theme, $bgColor }) =>
    $bgColor ? theme[$bgColor] : theme.white};
  gap: ${({ $noGap }) => ($noGap ? 0 : "20px")};
  display: flex;
  flex-direction: ${({ direction = "row" }) => direction};
  margin: ${({ margin }) => margin};
  padding: ${({ $padding = "15px" }) => $padding};
  align-items: ${({ $alignItems = "center" }) => $alignItems};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  box-shadow: ${({ theme }) => theme.boxShadow};
  box-sizing: border-box;
`;
