import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ConfigProvider } from "antd";
import { ThemeProvider as StyledComponentThemeProvider } from "styled-components";
import moment from "moment";
import "moment/locale/zh-hk";
import { DEFAULT_THEME, ANT_THEME } from "constants/theme";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import i18n from "./i18n";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

library.add(fas);

moment.locale(process.env.REACT_APP_LANGUAGE);
const theme = createTheme(DEFAULT_THEME);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StyledComponentThemeProvider theme={DEFAULT_THEME}>
    <ConfigProvider theme={ANT_THEME}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </I18nextProvider>
      </ThemeProvider>
    </ConfigProvider>
  </StyledComponentThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
