export const breakpoints = {
  desktop: 1280,
  tablet: 1024,
  mobile: 768,
  miniMobile: 480,
};

export const devices = {
  desktop: `(max-width: ${breakpoints.desktop}px)`,
  tablet: `(max-width: ${breakpoints.tablet}px)`,
  mobile: `(max-width: ${breakpoints.mobile}px)`,
  miniMobile: `(max-width: ${breakpoints.miniMobile}px)`,
};
