import { errorTextHelper } from "base/ErrorHelper";
import { useTranslation } from "react-i18next";

export default function useHandler(TOAST, ALERT) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  function toastHandler(errorMsg = "", type = "error") {
    if (!errorMsg) {
      return;
    }
    if (typeof errorMsg === "object") {
      TOAST[type](errorMsg?.title, errorMsg?.text);
      return;
    }

    TOAST[type](errorMsg);
  }

  function errorHandler(responseBody = {}, inputProps = {}) {
    const { errorCode = "", name } = responseBody || {};

    const alertProps = {
      title: "Error",
      text: "",
      showCloseButton: false,
      allowOutsideClick: false,
      remarks: "",
      ...inputProps,
    };

    // Front End Error
    if (errorCode === "frontEndError") {
      const { remarks, description, title } = responseBody || {};
      alertProps.text = description;
      alertProps.remarks = remarks;
      alertProps.title = title;
      ALERT.error(alertProps);
      return;
    }

    // v1 format
    if (!errorCode) {
      const { message } = responseBody || {};

      alertProps.text =
        message?.[language] || message?.en || message || t("Popup.unKnow");

      ALERT.error(alertProps);
      return;
    }

    // v2 format
    const errorText = errorTextHelper(responseBody);
    const multiLangText = errorText?.[language] || errorText;
    const [module, errorType, customCase] = errorCode.split("-") || [];

    if (errorType === "F") {
      toastHandler(multiLangText, "error");
      return;
    }

    alertProps.title = `Error (${errorCode})`;
    alertProps.text = multiLangText;

    // Customized error text
    // if (errorCode === "002-N-PBP000") {
    //   alertProps.text = "Invalid Merchant";
    //   ALERT.error(alertProps);
    //   return;
    // }

    // if (name === "Expired Authentication") {
    //   ALERT.error({
    //     ...alertProps,
    //     onConfirm: () => {
    //       userModel.logout();
    //     },
    //   });
    //   return;
    // }

    ALERT.error(alertProps);
  }

  return {
    toastHandler,
    errorHandler,
  };
}
