import React from "react";
import { BackDrop, Wrapper, BoxWrap, Box } from "./Spinner.styles";

function Spinner() {
  return (
    <BackDrop>
      <Wrapper>
        <BoxWrap>
          <Box className="one" />
          <Box className="two" />
          <Box className="three" />
          <Box className="four" />
          <Box className="five" />
          <Box className="six" />
        </BoxWrap>
      </Wrapper>
    </BackDrop>
  );
}

export default Spinner;
