import styled from "styled-components";

export const IconContainer = styled.div`
  width: ${({ $sm }) => ($sm ? "50px" : "80px")};
  height: ${({ $sm }) => ($sm ? "50px" : "80px")};
  border-radius: 40px;
  border: 1px solid ${({ theme }) => theme.borderGray};
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: flex;
  img {
    width: ${({ $sm }) => ($sm ? "50px" : "80px")};
    height: ${({ $sm }) => ($sm ? "50px" : "80px")};
  }
`;
