import { useContext } from "react";
import BaseActions from "base/BaseActions";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "pointItem";

const usePointItemModel = (savedModelInstance = {}) => {
  const { token, Organization } = useContext(UserModel.Context);

  const { getList, getOne, getSchema, createOne, updateOne, deleteOne } =
    BaseActions(module, { token, Organization });

  return {
    getList,
    getOne,
    getSchema,
    createOne,
    updateOne,
    deleteOne,
  };
};

const PointItemModel = BaseModel(usePointItemModel, "PointItemModel");

export { usePointItemModel };
export default PointItemModel;
