import React, { useContext, useMemo } from "react";
import PopUpModel from "models/popUpModel";
import { useTransition } from "@react-spring/web";
import { FlexDiv, StyledP } from "base/BaseStyle";
import {
  Container,
  Content,
  Icon,
  StyledToast,
  TextContainer,
} from "./Toast.styles";
import { TOAST_TIMEOUT, TRANSACTION_CONFIG, typeAttribute } from "./constants";
import CustomButton from "../../CustomButton";

function Toast() {
  const { theme, toastList, toggleToast } = useContext(PopUpModel.Context);

  const refMap = useMemo(() => new WeakMap(), []);
  const cancelMap = useMemo(() => new WeakMap(), []);

  const transitions = useTransition(toastList, {
    from: { opacity: 0, height: 0, life: "100%", x: 20 },
    keys: (item) => item.id,
    enter: (item) => async (next, cancel) => {
      cancelMap.set(item, cancel);
      await next({
        opacity: 1,
        height: refMap.get(item).offsetHeight + 20,
        x: 0,
      });
      await next({ life: "0%" });
    },
    leave: { opacity: 0, height: 0, x: 20 },
    onRest: (result, ctrl, item) => {
      toggleToast(item.id);
    },
    config: (item, index, phase) => (key) =>
      phase === "enter" && key === "life"
        ? { duration: item?.timeout || TOAST_TIMEOUT }
        : TRANSACTION_CONFIG,
  });

  return (
    <Container>
      {transitions(({ life, ...style }, item) => {
        const { title, text, type } = item || {};
        const { icon, color } = typeAttribute(type, theme);

        return (
          <StyledToast style={style}>
            <Content ref={(ref) => ref && refMap.set(item, ref)}>
              <FlexDiv $gap="20">
                <Icon src={icon} alt="type" />
                <TextContainer>
                  <StyledP color={color} $fontWeight="600" fontSize="18">
                    {title}
                  </StyledP>
                  <StyledP>{text}</StyledP>
                </TextContainer>
              </FlexDiv>
              <CustomButton
                variant="close"
                onClick={(e) => {
                  e.stopPropagation();
                  if (cancelMap.has(item) && life.get() !== "0%")
                    cancelMap.get(item)();
                }}
              />
            </Content>
          </StyledToast>
        );
      })}
    </Container>
  );
}

export default Toast;
