export default {
  status: "Status",
  updatedDate: "Updated Date",
  day: "Day",
  hour: "Hour",
  minute: "Minute",
  safetyPoint: "Safety Point",
  rewardPoint: "Reward Point",
  updatedAt: "Last Updated",

  Popup: {
    created: "The record was successfully created.",
    updated: "The record was successfully updated.",
    deleted: "The record was successfully deleted.",
    uploaded: "The file was successfully uploaded.",
    imported: "The record was successfully imported.",
    sent: "The sms was successfully sent",
    canceled: "The booking was successfully cancelled",
    generated: "The information was successfully generated.",
    confirmationNoteResent: "The confirmation notice was successfully resent.",
    terminated: "The booking was successfully terminated",
    unsaved: "Unsaved Changes",
    leaveWithoutSaving: "Leave without saving",
    confirmDelete: "Confirm to delete the record?",
    areYouSure: "Are you sure?",
    lostDataWarning:
      "You are about to leave this page without saving.\nAll changes will be lost.\nDo you really want to leave without saving?",
    bathUploadFailed: "File number {{number}} upload failed, please try again.",
    unKnow: "Unknown Error. Please try again.",
  },
  Button: {
    save: "Save",
    update: "Update",
    close: "Close",
    cancel: "Cancel",
    reset: "Reset",
    search: "Search",
    delete: "Delete",
    confirm: "Confirm",
    add: "Add",
    back: "Back",
    refresh: "Refresh",
    redirect: "Redirect",
    logout: "Log Out",
    show: "Show ",
    downloadCSV: "Download CSV",
    download: "Download",
  },
  Status: {
    active: "Active",
    inactive: "Inactive",
    pending: "Pending",
    pendingApproval: "Pending Approval",
    suspended: "Suspended",
    completed: "Completed",
    rejected: "Rejected",
    approved: "Approved",
    updatedAt: "Last updated",
  },
  Menu: {
    Home: "Home",
    Admins: "Admins",
    PointManagement: "Point Management",
    RiskReport: "Risk Report",
    RiskManagement: "Risk Management",
    UserGroup: "User Group",
    RewardsPoint: "Rewards Point",
    SafetyPoint: "Safety Point",
    Users: "App Users",
    SystemOptions: "System Options",
    Certificate: "Certificate",
    SiteAndZone: "Site & Zone",
    Courses: "Courses",
    UserDetail: "App User Detail",
    UserPoint: "User and Point Details",
    ZonePoint: "Zone Point Details",
    MyAccount: "My Account",
    Test: "Test",
    ContentUpdate: "Content Update",
    SafetyReason: "Point Item",
    SubContractor: "SubContractor",
    TotalOf: "Total of",
    NumberOfSearchResults: "search results",
    Tags: "Tags",
    Report: "Reporting",
  },
  Auth: {
    welcome: "Welcome!",
    login: "Login",
    forgotPwd: "忘記密碼",
    tnc: "登錄即表示您同意我們的使用條款並接收電子郵件、更新並確認您已閱讀我們的隱私政策。",
  },
  Home: {
    welcome: "歡迎來到Build King。",
  },
  Users: {
    id: "ID",
    name: "English Name",
    chineseName: "Chinese Name",
    phone: "Phone Number",
    code: "Code",
    role: "Role",
    viewPoint: "Safety / Reward Points",
    displayCode: "Display QR Code",
    qrCode: "User QR Code",
    basicInfo: "Basic Information",
    lastName: "Surname (English)",
    firstName: "First Name (English)",
    lastNameZh: "Surname (Chinese)",
    firstNameZh: "First Name (Chinese)",
    hkid: "HKID No.",
    workInfo: "Work Information",
    userId: "User ID",
    status: "Status",
    subContractor: "Subcontractor",
    cistc: "Green Card Information",
    cwrc: "CWRC",
    hkie: "HKIE",
    hkicm: "HKICM",
    refNumber: "Card Number",
    cardName: "Name",
    memberShip: "Membership Class",
    dateOfCompletion: "Course Completion Date",
    validFrom: "Valid From",
    validTo: "Valid To",
    dateOfIssue: "Date Of Issue",
    siteInfo: "Site and Zone",
    expandAll: "Expand All",
    addZone: "Add New",
    allZone: "All Zone",
    zone: "Zone",
    updatedAt: "Last Updated",
    createdAt: "Created Date",
    username: "Email Address (for Login)",
    password: "Password",
    confirmPassword: "Confirm Password",
    changePassword: "Change Password",
    currentPassword: "Current Password",
    newPassword: "New Password",
    authInfo: "Login Information",
    phoneChecking: "Check Phone Number",
    roleSelection: "Select Position",
    infoSetting: "Info Setting",
    position: "Position",
    dateOfBirth: "Date Of Birth",
    gender: "Gender",
    f: "Female",
    m: "Male",
    preferredLanguage: "Preferred Language",
    userNotFound: "User not found. User must send an otp in the app first.",
    userExist:
      "The user has already registered. Please enter the correct phone number.",
    selectCard: "Please select the certificate you hold",
    cardType: "Card Type",
    en: "English",
    zh: "繁體中文",
    zhCHS: "简体中文",
    phoneExist: "The phone number has already been registered.",
    SubContractor: "SubContractor",
    Site: "Construction Site (For filter)",
  },
  RiskReport: {
    id: "Report ID",
    title: "Title",
    location: "Location",
    created: "Report Date",
    reportInfo: "Report Information",
    followUpStatus: "Follow-up Status",
    editStatus: "Edit Status",
    status: "Status",
    remarks: "Remarks",
    constructionSite: "Construction Site",
    description: "Description",
    reporter: "Reporter",
  },
  Role: {
    unknown: "Unknown",
    cmsAdmin: "CMS Admin",
    siteAdmin: "Site Admin",
    manager: "Manager",
    safetyAdmin: "Safety Admin",
    worker: "Worker",
    frontline: "Frontline",
    external: "External",
    guest: "Guest",
  },
  SiteConfig: {
    site: "Construction Site",
    zone: "Zone",
  },
  Courses: {
    name: "Course Name",
    nameEn: "Course Name (EN)",
    nameZh: "Course Name (ZH)",
    credit: "Credit",
    offset: "No. of points offset",
    pointItemName: "Point Name",
    pointItemType: "Point Type",
    pointItemValue: "Point Value",
  },
  SafetyReason: {
    reason: "Reason",
    point: "Point",
    category: "Category",
    reasonEn: "Reason (En)",
    reasonZh: "Reason (Zh)",
    pointNumber: "No. of Point",
    value: "Value",
    type: "Type",
    safetyPoint: "Safety Point",
    rewardPoint: "Reward Point",
    credit: "Credit (-)",
    debit: "Debit (+)",
    priority: "Priority",
  },
  Certificate: {
    title: "Title",
    user: "User(Name / Phone Number)",
    type: "Type",
    id: "Certificate ID",
    created: "Report Date",
    phone: "Phone Number",
    certificate: "Certificate",
    reporter: "Reporter",
    description: "Description",
    reason: "Reason",
    followUpStatus: "Follow-up status",
    remarks: "Remarks",
    status: "Status",
    editStatus: "Edit Status",
  },
  PointManagement: {
    User: "User",
    Zone: "Zone",
    Site: "Site",
    name: "Name",
    chineseName: "Chinese Name",
    phone: "Phone Number",
    userId: "User ID",
    zoneName: "Zone Name",
    zoneId: "Zone ID",
    safetyPoint: "Safety Point",
    rewardPoint: "Reward Point",
    FilterBySiteOrZone: "Filter by Site or Zone",
    reason: "Reason",
    total: "Total",
    scoreForSelectedArea: "score for selected area",
    points: " Points",
    inspectUserProfile: "Go to Profile",
    belongingConstructionSite: "Belonging construction sites",
    title: "Title",
    siteDiscovered: "Site Discovered",
    createdDate: "Created Date",
    description: "Description",
    tabSafety: "Safety Score",
    tabReward: "Reward Score",
    none: "None",
    good: "Good",
    slight: "Slight",
    moderate: "Moderate",
    severe: "Severe",
    severity: "Severity",
    credit: "Credit",
    onlyLast12Months: "Only points from the past twelve months are recorded",
    modalSafetyPoints: "Safety Point",
    modalRewardPoints: "Reward Point",
    rewardPoints: "Reward Points",
    add: "Add ",
    formReason: "Reason",
    formValue: "Points",
    formDescription: "Description",
    formZone: "Zone Discovered",
  },
  SubContractor: {
    nameEn: "Name (EN)",
    nameZh: "Name (ZH)",
    name: "Name",
  },
  ContentUpdate: {
    en: "English",
    zh: "Chinese",
    zhCHS: "Simplified Chinese",
    page: "Page",
    tnc: "Terms and Conditions",
    pp: "Privacy Policy (not implemented)",
  },
  Validation: {
    required: "Required",
    invalidPhone: "Invalid Phone Number",
  },
  Tag: {
    type: "Type",
    value: "Value",
    name: "Name",
    Ref: "Ref",
    refType: "Ref Type",
    constructionSite: "Construction Site",
  },
  Report: {
    rewardPointTransaction: "Daily Merit Point Download",
    safetyPointTransaction: "Daily Safety Point Download",
    from: "From",
    to: "To",
  },
};
