import { useContext } from "react";
import BaseActions from "base/BaseActions";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "PointTransaction";

const usePointTransactionModel = (savedModelInstance = {}) => {
  const { token } = useContext(UserModel.Context);

  const { getList, getOne, getSchema, createOne, updateOne, deleteOne } =
    BaseActions(module, { token });

  return {
    getList,
    getOne,
    getSchema,
    createOne,
    updateOne,
    deleteOne,
  };
};

const PointTransactionModel = BaseModel(
  usePointTransactionModel,
  "PointTransactionModel",
);

export { usePointTransactionModel };
export default PointTransactionModel;
