import React from "react";
import {
  RISK_REPORT,
  HOME,
  USERS,
  POINT_MANAGEMENT,
  COURSES,
  CERTIFICATE,
  MY_ACCOUNT,
  ADMINS,
  CONTENT_UPDATE,
  SAFETY_REASON,
  SITE_AND_ZONE,
  SUB_CONTRACTOR,
  TAGS,
  REPORT,
} from "./paths";

const RiskReport = React.lazy(() => import("views/RiskReport"));
const Home = React.lazy(() => import("views/Home"));
const Users = React.lazy(() => import("views/Users"));
const PointManagement = React.lazy(() => import("views/PointManagement"));
const SiteAndZone = React.lazy(() => import("views/SiteAndZone"));
const Courses = React.lazy(() => import("views/Courses"));
const Certificate = React.lazy(() => import("views/Certificate"));
const MyAccount = React.lazy(() => import("views/MyAccount"));
const Admins = React.lazy(() => import("views/Admins"));
const ContentUpdate = React.lazy(() => import("views/ContentUpdate"));
const SafetyReason = React.lazy(() => import("views/SafetyReason"));
const SubContractor = React.lazy(() => import("views/SubContractor"));
const Tags = React.lazy(() => import("views/Tags"));
const Report = React.lazy(() => import("views/Report"));

const COMPONENTS = {
  [RISK_REPORT]: RiskReport,
  [HOME]: Home,
  [USERS]: Users,
  [POINT_MANAGEMENT]: PointManagement,
  [SITE_AND_ZONE]: SiteAndZone,
  [COURSES]: Courses,
  [CERTIFICATE]: Certificate,
  [MY_ACCOUNT]: MyAccount,
  [ADMINS]: Admins,
  [CONTENT_UPDATE]: ContentUpdate,
  [SAFETY_REASON]: SafetyReason,
  [SUB_CONTRACTOR]: SubContractor,
  [TAGS]: Tags,
  [REPORT]: Report,
};

function getGrantedRoutes(grantedMenuItems) {
  return grantedMenuItems.reduce((acc, input) => {
    if (
      input.children &&
      Array.isArray(input.children) &&
      input.children.length > 0
    ) {
      input.children.forEach((e) => {
        const path = e.nestedPath ? `${e.path}/*` : e.path;

        if (!COMPONENTS[e.path]) {
          return;
        }

        acc.push({
          key: path,
          path,
          component: COMPONENTS[e.path],
        });
      });
      return acc;
    }

    if (!COMPONENTS[input.path]) {
      return acc;
    }

    return [
      ...acc,
      {
        key: input.path,
        path: input.nestedPath ? `${input.path}/*` : input.path,
        component: COMPONENTS[input.path],
      },
    ];
  }, []);
}

export default COMPONENTS;
export { getGrantedRoutes };
