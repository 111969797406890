import styled from "styled-components";
import { animated } from "@react-spring/web";

export const StyledToast = styled(animated.div)`
  box-sizing: border-box;
  position: relative;
  width: 400px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-top: 20px;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 20px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ theme }) => theme.boxShadow};
  position: relative;
  border-radius: 10px;

  > button {
    position: absolute;
    top: 10px;
    right: 10px;

    > svg {
      height: 12px;
      width: 12px;
    }
  }
`;

export const Icon = styled.img`
  width: 40px;
  height: 40px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 40px;
  padding-left: 15px;
  border-left: 1px solid ${({ theme }) => theme.borderGray};
`;
