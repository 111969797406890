import styled from "styled-components";

export const TagContainer = styled.div`
  border-radius: 20px;
  color: ${({ theme }) => theme.white};
  background-color: ${({ $bgColor, theme }) => theme[$bgColor]};
  text-align: center;
  font-size: 14px;
  padding: 8px 20px;
  display: inline-block;
`;
