import { useCallback, useContext, useMemo, useState } from "react";
import BaseModel from "base/BaseModel";
import { isEmpty } from "lodash";
import { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import {
  getGrantedActions,
  getGrantedMenuItems,
  getResourceFromPath,
} from "base/PermissionHelper";
import { errorResponseGenerator } from "base/ErrorHelper";
import GeneralModel from "./generalModel";

const module = "user";

const useUserModel = (savedModelInstance = {}) => {
  // Region External Hooks

  const { location, navigate, TOAST, ALERT } = useContext(GeneralModel.Context);

  // End Region

  // Region State, Memos, Refs

  const [token, setToken] = useState(savedModelInstance?.token);
  const [userData, setUserData] = useState(savedModelInstance?.userData);

  const isLoggedIn = useMemo(() => !!userData?._id, [userData?._id]);
  const Organization = useMemo(() => userData?.Organization?._id, [userData]);
  const organizationData = useMemo(() => userData?.Organization, [userData]);
  const isSystem = useMemo(() => userData?.level === "system", [userData]);

  const grantedPermissions = useMemo(
    () => userData?.UserGroup?.permissions || [],
    [userData?.UserGroup?.permissions],
  );

  const grantedMenuItems = useMemo(() => {
    if (isEmpty(userData)) {
      return [];
    }

    return getGrantedMenuItems(userData?.UserGroup);
  }, [userData?.UserGroup]);

  const grantedActions = useMemo(() => {
    const path = getResourceFromPath(location);
    // TODO: permission
    if (userData?.UserGroup?.level === "organization") {
      return ["*"];
    }
    return getGrantedActions(grantedPermissions, path);
  }, [grantedPermissions, location]);

  // End Region

  // Region API Calls

  async function login(input) {
    const { url } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/Auth/login`,
      null,
      null,
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        input,
        true,
        null,
      );

      if (response?.data?.token) {
        setToken(response.data.token);
      }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function logout() {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/Auth/logout`,
      null,
      token,
    );

    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        null,
        true,
        headers,
      );

      setToken(null);
      setUserData({});

      const modal = window.document.getElementById("styledDialog");
      if (modal) {
        modal.style.display = "none";
      }
      TOAST.closeAll();
      ALERT.close();
      window.localStorage.removeItem("UserModel");
      navigate("/", { replace: true });

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getMe(inputToken = token) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/getMe`,
      null,
      inputToken,
    );
    try {
      const { records } = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers,
      );
      const [data] = records || {};
      const { UserGroup } = data;
      const {
        permissions = [],
        level = "system",
        isAllGranted,
      } = UserGroup || {};

      if (!UserGroup) {
        throw errorResponseGenerator(
          "Fail to get User Group, Please try again",
        );
      }

      if (data) {
        getGrantedMenuItems(UserGroup);
        setUserData({
          ...data,
          permissions,
          level,
          isAllGranted,
        });
      }
      return Promise.resolve(data);
    } catch (error) {
      setUserData({});
      await logout();
      return Promise.reject(error);
    }
  }

  async function getList(params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}`,
      isSystem ? params : { Organization, ...params },
      token,
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers,
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getOne(id, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}`,
      params,
      token,
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers,
      );

      const { records } = response || {};
      const [record = {}] = records || [];

      return Promise.resolve(record);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function createOne(data, platform = "cms") {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { Organization, platform, ...data },
        true,
        headers,
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function updateOne(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        data,
        true,
        headers,
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function deleteOne(id) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "DELETE",
        url.toString(),
        null,
        true,
        headers,
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function generateQRCodeString(id) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/generateQRCodeString`,
      null,
      token,
    );
    try {
      const { data = {} } = await APIHelper(
        "PUT",
        url.toString(),
        null,
        true,
        headers,
      );
      const { url: qrCodeString = "" } = data || {};

      return Promise.resolve(qrCodeString);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getSchema() {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/schema`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers,
      );

      const { schema = {} } = response || {};

      return Promise.resolve(schema);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function changePassword(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/changePassword`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        data,
        true,
        headers,
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function createAuth(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/createAuth`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        data,
        true,
        headers,
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function checkPhoneNumber(data, platform = "cms") {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/checkPhoneNumber`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { Organization, platform, ...data },
        true,
        headers,
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function checkAuthUsername(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/Auth/checkUsername`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { Organization, ...data },
        true,
        headers,
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function deleteAllDocuments(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/deleteAllDocuments`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        data,
        true,
        headers,
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function checkHKID(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/checkHKID`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { Organization, hkid: data },
        true,
        headers,
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // End Region

  // Region Callbacks

  const onSaveInstanceState = useCallback(
    () => ({
      token,
      userData,
    }),
    [token, userData],
  );

  // End Region

  return {
    token,
    userData,
    organizationData,
    Organization,
    isSystem,
    isLoggedIn,
    grantedPermissions,
    grantedActions,
    grantedMenuItems,

    logout,
    onSaveInstanceState,

    // API Calls
    login,
    getMe,
    getList,
    getOne,
    createOne,
    updateOne,
    deleteOne,
    generateQRCodeString,
    getSchema,
    changePassword,
    createAuth,
    checkPhoneNumber,
    checkAuthUsername,
    deleteAllDocuments,
    checkHKID,
  };
};

const UserModel = BaseModel(useUserModel, "UserModel");

export { useUserModel };
export default UserModel;
