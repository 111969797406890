import APIHelper from "base/APIHelper";
import BaseActions, { APIRequestGenerator } from "base/BaseActions";
import BaseModel from "base/BaseModel";
import { useContext } from "react";
import UserModel from "./userModel";

const module = "zone";

const useZoneModel = (savedModelInstance = {}) => {
  const { token, Organization } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne } = BaseActions(module, {
    token,
    Organization,
  });

  async function getSafetyPoint(params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/safetyPoint/`,
      { Organization },
      token,
    );

    const { _id, ConstructionSite } = params ?? {};
    let queryString = "";

    if (_id && _id?.length > 0) {
      queryString += _id
        .map((value) => `&_id=${encodeURIComponent(value)}`)
        .join("");
    }

    if (ConstructionSite && ConstructionSite?.length > 0) {
      queryString += ConstructionSite.map(
        (value) => `&ConstructionSite=${encodeURIComponent(value)}`,
      ).join("");
    }

    try {
      const response = await APIHelper(
        "GET",
        url.toString() + queryString,
        null,
        true,
        headers,
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    getSafetyPoint,
  };
};

const ZoneModel = BaseModel(useZoneModel, "ZoneModel");

export { useZoneModel };
export default ZoneModel;
