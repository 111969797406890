import React from "react";
import CustomButton from "components/CustomButton";
import NotFoundImage from "assets/images/notFound.png";
import { useNavigate } from "react-router-dom";
import { Container } from "./NotFound.styles";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <Container>
      <img src={NotFoundImage} alt="notFound" />
      <CustomButton onClick={() => navigate(-1)}>Go Back</CustomButton>
    </Container>
  );
}
