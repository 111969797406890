import React, { createContext, useCallback, useEffect, useState } from "react";

function PersistedViewComponent({ model, resourceKey, children }) {
  const [pageHideAbortController, setPageHideAbortController] = useState(null);

  const onSaveInstanceState = useCallback(() => {
    if (typeof model?.onSaveInstanceState === "function") {
      const modelInstance = model.onSaveInstanceState();
      window.localStorage.setItem(resourceKey, JSON.stringify(modelInstance));
    }
  }, [model?.onSaveInstanceState]);

  useEffect(() => {
    const unloadListener = () => {
      onSaveInstanceState();
    };
    if (pageHideAbortController) pageHideAbortController.abort();
    const newAbortController = new AbortController();

    window.addEventListener("pagehide", unloadListener, {
      signal: newAbortController.signal,
    });
    setPageHideAbortController(newAbortController);
  }, [onSaveInstanceState]);

  useEffect(() => {
    if (typeof model?.onSaveInstanceState === "function") {
      const modelInstance = model.onSaveInstanceState();
      window.localStorage.setItem(resourceKey, JSON.stringify(modelInstance));
    }
  }, [model?.onSaveInstanceState]);

  return children;
}

const BaseModel = function (model, resourceKey) {
  const Context = createContext(null);
  const withProvider = (ViewComponent) =>
    function (props) {
      const savedModelInstance =
        window.localStorage.getItem(resourceKey) || "{}";
      const value = model(JSON.parse(savedModelInstance));

      return (
        <Context.Provider value={value}>
          <PersistedViewComponent model={value} resourceKey={resourceKey}>
            <ViewComponent {...props} />
          </PersistedViewComponent>
        </Context.Provider>
      );
    };
  return {
    Context,
    withProvider,
  };
};

export default BaseModel;
