import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 0;
  margin-top: ${({ $headerHeight }) => `${$headerHeight}px`};
  margin-left: ${({ $menuWidth, $showMenu }) =>
    $showMenu ? `${$menuWidth}px` : 0};
  background-color: ${({ theme }) => theme.secondary};
  box-sizing: border-box;
  overflow: scroll;
  transition: all 300ms;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  header + div {
    padding: 20px 30px;
  }
`;

// Page Header

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.secondary};
  box-sizing: border-box;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 10;
`;
