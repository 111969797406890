import BaseModel from "base/BaseModel";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useContext, useState } from "react";
import useHandler from "hooks/useHandler";
import PopUpModel from "./popUpModel";
import { APIRequestGenerator } from "../base/BaseActions";
import APIHelper from "../base/APIHelper";

const useGeneralModel = (savedModelInstance = {}) => {
  // Region External Hooks

  const navigate = useNavigate();
  const location = useLocation();
  const { theme, t, i18n, TOAST, ALERT } = useContext(PopUpModel.Context);
  const { errorHandler, toastHandler } = useHandler(TOAST, ALERT);

  // End Region

  // Region State, Memos, Refs
  const [isLoading, setIsLoading] = useState(false);
  // End Region

  // Region Functions and Callbacks

  const changeLoading = useCallback((loading) => {
    setIsLoading(loading);
  }, []);

  const handleUploadFile = async (token, file) => {
    const { url } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/media/upload`,
      null,
      token,
    );
    try {
      const { data } = await APIHelper("POST", url.toString(), file, false, {
        accept: "application/json, text/plain, /",
        Authorization: `Bearer ${token}`,
      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  // End Region

  return {
    navigate,
    location,
    theme,
    t,
    i18n,
    TOAST,
    ALERT,
    errorHandler,
    toastHandler,

    isLoading,
    changeLoading,
    handleUploadFile,
  };
};

const GeneralModel = BaseModel(useGeneralModel, "GeneralModel");

export { useGeneralModel };
export default GeneralModel;
