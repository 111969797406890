import styled, { keyframes } from "styled-components";

const load = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  display: flex;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  justify-content: center;
  align-items: center;
  color: ${({ $color }) => $color};

  &:after {
    content: " ";
    display: block;
    width: ${({ width }) => `${width - 10}px`};
    height: ${({ height }) => `${height - 10}px`};
    border-radius: 50%;
    border: ${({ $color }) => `2px solid ${$color}`};
    border-color: ${({ $color }) =>
      `${$color} transparent ${$color} transparent`};
    animation: ${load} 1.2s linear infinite;
  }
`;
