import React, { useCallback, useContext, useEffect, useState } from "react";
import UserModel from "models/userModel";
import { useLocation } from "react-router-dom";
import { BodyContainer, StyledMenuContainer } from "../SlideMenu.styles";
import MenuItem from "./MenuItem";

export default function MenuBody() {
  const { userData, grantedMenuItems } = useContext(UserModel.Context);
  const { level } = userData || {};
  const [expand, setExpand] = useState("");

  const changeExpand = useCallback((data) => setExpand(data), []);

  return (
    <BodyContainer>
      <StyledMenuContainer>
        {level &&
          grantedMenuItems.map((item) => (
            <MenuItem
              key={item.name}
              item={item}
              expand={expand === item.name}
              changeExpand={changeExpand}
            />
          ))}
      </StyledMenuContainer>
    </BodyContainer>
  );
}
