import styled from "styled-components";
import { Link } from "react-router-dom";

const BasicButton = styled.button`
  height: 40px;
  min-width: 68px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  line-height: 20px;
  padding: 11px 20px;
  color: #fff;
  border: none;
  letter-spacing: 0.02857em;
  vertical-align: middle;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  white-space: nowrap;
`;

export const PrimaryButton = styled(BasicButton)`
  background-color: ${({ theme, $bgColor }) =>
    $bgColor ? theme?.[$bgColor] : theme.primary};
  color: ${({ theme, color }) => (color ? theme?.[color] : theme.white)};

  svg,
  img {
    margin-left: 10px;
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.primary};
    }

    img {
      filter: invert(64%) sepia(20%) saturate(3987%) hue-rotate(183deg)
        brightness(95%) contrast(87%);
    }
  }

  &:disabled {
    background-color: #dedede;
  }
`;

export const SecondaryButton = styled(BasicButton)`
  background-color: ${({ theme, $bgColor }) =>
    $bgColor ? theme?.[$bgColor] : theme.white};
  color: ${({ theme, color }) => (color ? theme?.[color] : theme.primary)};
  text-decoration: underline;
  text-underline-offset: 2px;

  svg,
  img {
    margin-left: 10px;
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.primary};
    }

    img {
      filter: invert(64%) sepia(20%) saturate(3987%) hue-rotate(183deg)
        brightness(95%) contrast(87%);
    }
  }

  &:disabled {
    background-color: #dedede;
  }
`;

export const NavigateButton = styled(Link)`
  background-color: transparent;
  box-shadow: none;
  color: ${({ color = "primary", theme }) => theme?.[color]};
  padding: 0px;
  font-weight: bold;
  width: fit-content;

  &:disabled {
    background-color: transparent;
    opacity: 0.3;
    color: ${({ color = "primary", theme }) => theme?.[color]};
  }
`;

export const IconButton = styled(BasicButton)`
  background-color: ${({ $bgColor, theme }) =>
    $bgColor ? `${theme?.[$bgColor]}` : "transparent"};
  padding: 0;
  box-shadow: none;
  color: ${({ theme, color }) => color || theme.primary};
  min-width: unset;
  height: auto;

  svg {
    color: ${({ theme, color }) => color || `${theme.primary}`};
    height: ${({ $iconSize }) =>
      $iconSize?.height ? `${$iconSize.height}px` : "20px"};
    width: ${({ $iconSize }) =>
      $iconSize?.width ? `${$iconSize.width}px` : "20px"};
  }
`;

export const TextButton = styled(BasicButton)`
  background-color: transparent;
  box-shadow: none;
  color: ${({ color = "primary", theme }) => theme?.[color]};
  padding: 0;
  border-radius: unset;
  text-decoration: underline;
  text-underline-offset: 3px;

  &:disabled {
    background-color: transparent;
    opacity: 0.3;
    color: ${({ color = "primary", theme }) => theme?.[color]};
  }
`;

export const SquareButton = styled(BasicButton)`
  padding: 10px;
  display: flex;
  background-color: ${({ theme, color = "primary" }) => theme?.[color]};
  color: #fff;
  min-width: unset;
  width: 40px;
  height: 40px;

  &:disabled {
    background-color: #dedede;
    color: #fff;

    svg {
      color: #fff;
    }
  }

  svg {
    height: 30px;
    width: 30px;
  }
`;
