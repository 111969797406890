export default function customCaseTable(customCase) {
  switch (customCase) {
    case "BUK051": {
      return {
        en: "Duplicated HKID",
        zh: "Duplicated HKID",
      };
    }
    default:
      return "";
  }
}
