import styled from "styled-components";
import { Input } from "antd";

export const TextInput = styled(Input)`
  &.ant-input {
    border-radius: 10px;
    font-size: 14px;

    &.ant-input-disabled {
      color: ${({ theme }) => theme.text};
    }
  }
`;

export const PasswordInput = styled(Input.Password)`
  &.ant-input-password {
    border-radius: 10px;
    font-size: 14px;

    .ant-input-password-icon svg {
      fill: ${({ theme }) => theme.primary};
    }
  }
`;
