/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import { isEmpty } from "base/BaseHelper";
import { PasswordInput, TextInput } from "./CustomInput.styles";

function CustomInput({
  name,
  type = "text",
  value,
  onChange,
  label,
  placeholder,
  disabled,
  defaultValue,
  style,
  error,
}) {
  if (type === "password") {
    return (
      <PasswordInput
        id={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder || label}
        disabled={disabled}
        defaultValue={defaultValue}
        styles={style}
        status={!isEmpty(error) ? "error" : ""}
        autocompleted="off"
      />
    );
  }

  return (
    <TextInput
      id={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder || label}
      disabled={disabled}
      defaultValue={defaultValue}
      styles={style}
      status={!isEmpty(error) ? "error" : ""}
      autocompleted="off"
    />
  );
}

export default CustomInput;
