import moment from "moment";

export const getMultiLangProperty = (item, inputLang = "zh") => {
  if (typeof item === "string") return item;
  if (!item) return "";
  if (typeof item !== "object") return "";
  if (item?.[inputLang]) return item[inputLang];
  return "";
};

export function getFullName(user = {}, language = "zh") {
  const { firstName, lastName } = user || {};

  if (language === "en") {
    return `${lastName?.en || ""} ${firstName?.en || ""}`;
  }

  return `${getMultiLangProperty(lastName, language)}${getMultiLangProperty(
    firstName,
    language,
  )}`;
}

export function formattedDate(
  date,
  format = "YYYY-MM-DD",
  lang = "en",
  defaultValue = "-",
) {
  if (!date) {
    return defaultValue;
  }

  if (format === "MM YYYY") {
    return lang === "en"
      ? moment(date).format("MM YYYY")
      : moment(date).format("M月 YYYY年");
  }

  if (format === "lll:ss") {
    return lang === "en"
      ? moment(date).format("DD MMM YYYY HH:mm:ss")
      : moment(date).format("YYYY年M月D日 HH時mm分ss秒");
  }

  if (format === "ll") {
    return lang === "en"
      ? moment(date).format("DD MMM YYYY")
      : moment(date).format("YYYY年M月D日");
  }

  if (format === "lll") {
    return lang === "en"
      ? moment(date).format("DD MMM YYYY HH:mm")
      : moment(date).format("YYYY年M月D日 HH時mm分");
  }

  if (format === "DD MMMM") {
    return lang === "en"
      ? moment(date).format("DD MMMM")
      : moment(date).format("M月D日");
  }

  if (format === "YYYY") {
    return lang === "en"
      ? moment(date).format("YYYY")
      : moment(date).format("YYYY年");
  }

  return moment(date).format(format);
}

export function isEmpty(val) {
  if (typeof val === "boolean") {
    return !val;
  }
  return _.isEmpty(val);
}

export function getPhoneNumber(phone, showPrefix = true) {
  const { countryCode = "", number = "" } = phone || {};

  return showPrefix ? `+${countryCode} ${number}` : number;
}

export function resetFormValues(data, defaultValues) {
  if (isEmpty(data)) return defaultValues;
  const defaultKey = Object.keys(defaultValues);
  const target = Object.fromEntries(
    Object.entries(data).filter(([key]) => defaultKey.indexOf(key) !== -1),
  );
  return { ...defaultValues, ...target };
}

export function getSevereLevel(totalPoints) {
  if (totalPoints <= 0) {
    return "good";
  }
  if (totalPoints > 0 && totalPoints < 7) {
    return "slight";
  }
  if (totalPoints >= 7 && totalPoints < 12) {
    return "moderate";
  }
  return "severe";
}

export function isHKID(id) {
  /// Pre-Operation///

  id = id.replace(/[()]/g, "");

  // Check if the value inputted by user is a vaild and if the value consist 8 character, do the following
  if (id.length === 8) {
    // Convert character to ASCII code
    let letterASCII = id[0].charCodeAt();
    // Check if the value inputted by user is a vaild
    if (
      (letterASCII >= 65 && letterASCII <= 90) ||
      (letterASCII >= 97 && letterASCII <= 122)
    ) {
      // Convert lower case letter to upper case
      if (letterASCII >= 97 && letterASCII <= 122) {
        // Set character to upper case with ASCII code
        letterASCII -= 32;
      }
      // Convert letter to number for later on calculation by ASCII - 64
      const converted = letterASCII - 64;

      /// Operation///

      // Calculate product and sum of user inputted ID Card number
      const productNsum =
        converted * 8 +
        parseInt(id[1]) * 7 +
        parseInt(id[2]) * 6 +
        parseInt(id[3]) * 5 +
        parseInt(id[4]) * 4 +
        parseInt(id[5]) * 3 +
        parseInt(id[6]) * 2;
      // Find remainder of calculated product and sum
      const remainder = productNsum % 11;
      // Default check digit is zero
      let check = 0;
      let check_2 = 0; // In case the user enter a lower case HKID Card number
      // If remainder is not zero, run the following code
      if (remainder !== 0) {
        // Check digit is 11 - remainder
        check = 11 - remainder;
        check_2 = check;
        // If check digit is 10, show "A" as check digit
        if (check === 10) {
          check = "A";
          check_2 = "a";
        }
      }
      // Send back the result to user
      if (id[7] == check || id[7] == check_2) {
        return true;
      }
      return false;
    }
    return false;

    // Check if the value inputted by user is a vaild and if the value consist 9 character, do the following
  }
  if (id.length === 9) {
    // Convert character to ASCII code
    let letterASCII_1 = id[0].charCodeAt();
    let letterASCII_2 = id[1].charCodeAt();
    // Check if the value inputted by user is a vaild
    if (
      ((letterASCII_1 >= 65 && letterASCII_1 <= 90) ||
        (letterASCII_1 >= 97 && letterASCII_1 <= 122)) &&
      ((letterASCII_2 >= 65 && letterASCII_2 <= 90) ||
        (letterASCII_2 >= 97 && letterASCII_2 <= 122))
    ) {
      // Convert lower case letter to upper case
      if (
        (letterASCII_1 >= 97 && letterASCII_1 <= 122) ||
        (letterASCII_2 >= 97 && letterASCII_2 <= 122)
      ) {
        // Set character to upper case with ASCII code
        letterASCII_1 -= 32;
        letterASCII_2 -= 32;
      }
      // Convert letter to number for later on calculation by ASCII - 64
      const converted_1 = letterASCII_1 - 64;
      const converted_2 = letterASCII_2 - 64;

      /// Operation///

      // Calculate product and sum of user inputted ID Card number
      const productNsum =
        converted_1 * 9 +
        converted_2 * 8 +
        parseInt(id[2]) * 7 +
        parseInt(id[3]) * 6 +
        parseInt(id[4]) * 5 +
        parseInt(id[5]) * 4 +
        parseInt(id[6]) * 3 +
        parseInt(id[7]) * 2;
      // Find remainder of calculated product and sum
      const remainder = productNsum % 10;
      // Default check digit is zero
      let check = 0;
      let check_2 = 0; // In case the user enter a lower case HKID Card number
      // If remainder is not zero, run the following code
      if (remainder !== 0) {
        // Check digit is 11 - remainder
        check = 11 - remainder;
        // If check digit is 10, show "A" as check digit
        if (check === 10) {
          check = "A";
          check_2 = "a";
        }
      }
      // Send back the result to user
      if (id[8] == check || id[8] == check_2) {
        return true;
      }
      return false;
    }
    return false;
  }
  return false;
}

export function getReportDate(data, from, to, format = "YYYYMMDD") {
  if (!data) return "";
  const fromDate = data?.[from] ? formattedDate(data[from], format) : null;
  const toDate = data?.[to] ? formattedDate(data[to], format) : null;

  return fromDate && toDate ? `${fromDate}_${toDate}` : fromDate || toDate;
}
