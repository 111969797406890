import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import UserModel from "models/userModel";
import AppUIModel from "models/appUIModel";
import { MainContainer } from "./Layout.styles";
import SlideMenu from "./SlideMenu";

function Layout() {
  const { headerHeight, menuWidth, showMenu } = useContext(AppUIModel.Context);
  const { token } = useContext(UserModel.Context);

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message ===
          "ResizeObserver loop completed with undelivered notifications." ||
        e.message === "Script error."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div",
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay",
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  return (
    <>
      {token && <SlideMenu />}
      <MainContainer
        $headerHeight={headerHeight}
        $menuWidth={menuWidth}
        $showMenu={showMenu}
        id="main-container"
      >
        <Outlet />
      </MainContainer>
    </>
  );
}

export default Layout;
