import React, { useContext } from "react";
import UserModel from "models/userModel";
import { useTranslation } from "react-i18next";
import { HeaderContainer, LogOutButton } from "../SlideMenu.styles";
import Avatar from "../../../Avatar";

export default function MenuHeader() {
  const { t } = useTranslation();
  const { userData, organizationData, logout } = useContext(UserModel.Context);
  return (
    <HeaderContainer>
      <Avatar
        user={userData}
        organizationData={organizationData}
        showPhone={false}
        sm
      />
      <LogOutButton onClick={() => logout()}>{t("Button.logout")}</LogOutButton>
    </HeaderContainer>
  );
}
