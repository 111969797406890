export const LOGIN = "/";

export const ADMINS = "/Admin";
export const RISK_REPORT = "/RiskReport";
export const HOME = "/Home";
export const USERS = "/Users";
export const CERTIFICATE = "/Certificate";
export const POINT_MANAGEMENT = "/PointManagement";
export const SITE_AND_ZONE = "/SiteAndZone";
export const COURSES = "/Courses";
export const MY_ACCOUNT = "/MyAccount";
export const CONTENT_UPDATE = "/ContentUpdate";
export const SAFETY_REASON = "/SafetyReason";
export const SUB_CONTRACTOR = "/SubContractor";
export const TAGS = "/Tags";
export const REPORT = "/Report";
