import { useContext } from "react";
import BaseActions, { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "pointCategory";

const usePointCategoryModel = (savedModelInstance = {}) => {
  const { token, Organization } = useContext(UserModel.Context);

  const { getList, getOne, getSchema, createOne, updateOne, deleteOne } =
    BaseActions(module, { token, Organization });

  return {
    getList,
    getOne,
    getSchema,
    createOne,
    updateOne,
    deleteOne,
  };
};

const PointCategoryModel = BaseModel(
  usePointCategoryModel,
  "PointCategoryModel",
);

export { usePointCategoryModel };
export default PointCategoryModel;
