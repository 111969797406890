import React, { useContext, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppUIModel from "models/appUIModel";
import { FlexDiv, StyledP } from "base/BaseStyle";
import { useMeasure } from "react-use";
import { useSpring, to } from "@react-spring/web";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import {
  StyledParentItem,
  StyledMenuItem,
  StyledSubItem,
  StyledSubContainer,
  ExpandIcon,
} from "../SlideMenu.styles";

const ITEM_HEIGHT = 45;

export default function MenuItem({ item, expand, changeExpand }) {
  const { name, icon, children = [], path } = item || {};
  const params = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [ref, { height }] = useMeasure();
  const { windowWidth, toggleMenu } = useContext(AppUIModel.Context);
  const containerAnimatedStyle = useSpring({
    height: expand ? height + ITEM_HEIGHT + 10 : ITEM_HEIGHT,
  });

  const subContainerAnimatedStyle = useSpring({
    opacity: expand ? 1 : 0,
    x: expand ? 0 : 30,
  });

  function checkFocus(inputPath, isParent = false) {
    if (!pathname) {
      return false;
    }
    const childrenPath = children.map((e) => e?.path);

    if (params["*"]) {
      const removeParamsPath = pathname.replace(`/${params["*"]}`, "");

      if (!removeParamsPath) {
        return false;
      }

      return (
        removeParamsPath === inputPath ||
        (isParent ? childrenPath.includes(removeParamsPath) : false)
      );
    }

    return (
      pathname === inputPath ||
      (isParent ? childrenPath.includes(pathname) : false)
    );
  }

  const activeColor = useMemo(() => {
    if (expand || (checkFocus(path, true) && children.length)) {
      return "primary";
    }
    if (checkFocus(path, true) && !children.length) {
      return "white";
    }
    return "gray";
  }, [expand, checkFocus(path, true)]);

  useEffect(() => {
    if (checkFocus(path, true) && children.length > 0) {
      changeExpand(name);
    }
  }, []);

  return (
    <StyledMenuItem
      $focus={checkFocus(path, true)}
      $isParent={children.length > 0}
      style={containerAnimatedStyle}
    >
      <StyledParentItem
        $focus={checkFocus(path, true)}
        $isParent={children.length > 0}
        $expand={expand}
        onClick={() => {
          if (children.length > 0) changeExpand(expand ? "" : name);
          if (!path) return;
          navigate(path);
          if (windowWidth <= 980) {
            toggleMenu(false);
          }
        }}
        height={ITEM_HEIGHT}
      >
        <FlexDiv $gap="10">
          {icon}
          <StyledP fontSize="15" color={activeColor}>
            {t(`Menu.${name}`)}
          </StyledP>
        </FlexDiv>
        {children.length > 0 && <ArrowIcon />}
      </StyledParentItem>
      <StyledSubContainer
        ref={ref}
        style={{
          ...subContainerAnimatedStyle,
          transform: to(
            [subContainerAnimatedStyle.x],
            (value) => `translateX(${value}px)`,
          ),
        }}
      >
        {Array.isArray(children) &&
          children.map((child) => (
            <StyledSubItem
              key={child.name}
              $focus={checkFocus(child.path)}
              onClick={() => {
                if (!child.path) return;
                navigate(child.path);
                if (windowWidth <= 980) {
                  toggleMenu(false);
                }
              }}
              height={ITEM_HEIGHT}
            >
              <StyledP
                fontSize="15"
                color={checkFocus(child.path) ? "white" : "gray"}
              >
                {t(`Menu.${child.name}`)}
              </StyledP>
            </StyledSubItem>
          ))}
      </StyledSubContainer>
    </StyledMenuItem>
  );
}
