import React, { useCallback, useContext } from "react";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as ExportIcon } from "assets/icons/export.svg";
import { ReactComponent as ReadIcon } from "assets/icons/eye.svg";
import { ReactComponent as GroupIcon } from "assets/icons/group.svg";
import { getGrantedActions } from "base/PermissionHelper";
import UserModel from "models/userModel";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../LoadingSpinner";
import {
  IconButton,
  NavigateButton,
  PrimaryButton,
  SecondaryButton,
  SquareButton,
  TextButton,
} from "./CustomButton.styles";

function CustomButton({
  onClick = () => {},
  children,
  style,
  type = "button",
  variant = "primary",
  disabled,
  to,
  $text,
  $iconSize = {},
  $loading = false,
  color,
  $bgColor,
  customPermission,
  $disableAnimation = false,
}) {
  const { t } = useTranslation();
  const { grantedActions = [], grantedPermissions = [] } = useContext(
    UserModel.Context,
  );

  const isGrantedPermission = useCallback(() => {
    if (grantedActions.includes("*")) {
      return true;
    }

    let action = "";

    if (variant === "delete") {
      action = "delete";
    }

    if (variant === "add") {
      action = "create";
    }

    if (
      type === "submit" &&
      (variant === "createSave" || variant === "updateSave")
    ) {
      action = variant === "createSave" ? "create" : "update";
    }

    if (customPermission?.resource || customPermission?.action) {
      if (!customPermission?.resource) {
        return grantedActions.includes(customPermission?.action);
      }

      // custom resource
      const actions = getGrantedActions(
        grantedPermissions,
        customPermission?.resource,
      );

      return actions
        ? actions.includes(customPermission?.action || action)
        : false;
    }

    if (!action) {
      return true;
    }

    return grantedActions.includes(action);
  }, [grantedActions]);

  const props = {
    onClick,
    children,
    style,
    type,
    variant,
    disabled: !isGrantedPermission(variant, type) || disabled,
    to,
    $iconSize,
    $loading,
    $text,
    color,
    $bgColor,
    $disableAnimation,
  };

  switch (variant) {
    case "close": {
      return (
        <IconButton {...props}>
          <CloseIcon />
        </IconButton>
      );
    }
    case "export": {
      return (
        <PrimaryButton {...props}>
          {t("Button.downloadCSV")} <ExportIcon />
        </PrimaryButton>
      );
    }
    case "read": {
      return (
        <IconButton {...props}>
          <ReadIcon />
        </IconButton>
      );
    }
    case "add":
      return (
        <SquareButton {...props}>
          <AddIcon />
        </SquareButton>
      );
    case "refresh":
      return (
        <SquareButton {...props}>
          <GroupIcon />
        </SquareButton>
      );
    case "edit":
      return (
        <SquareButton {...props}>
          <EditIcon />
        </SquareButton>
      );
    case "icon":
      return <IconButton {...props}>{children}</IconButton>;
    case "text":
      return <TextButton {...props}>{children}</TextButton>;
    case "navigate":
      return (
        <NavigateButton target="_blank" to={to} {...props}>
          {children}
        </NavigateButton>
      );
    case "secondary":
      return <SecondaryButton {...props}>{children}</SecondaryButton>;
    default: {
      return (
        <PrimaryButton {...props}>
          {children}
          {!!$loading && (
            <LoadingSpinner style={{ marginLeft: 10 }} width="30" height="30" />
          )}
        </PrimaryButton>
      );
    }
  }
}

export default CustomButton;
