import React, { memo } from "react";
import CustomInput from "../../../CustomInput";
import ErrorMessage from "./ErrorMessage";

const Inputs = memo(
  ({
    type = "text",
    value,
    changeInputValue,
    validationMessage,
    changeValidationMessage,
    asyncConfirm,
  }) => {
    if (type === "text") {
      return (
        <>
          <CustomInput
            value={value}
            onChange={(e) => {
              changeInputValue(e.target.value);
              changeValidationMessage("");
            }}
            error={validationMessage}
            autoFocus
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                asyncConfirm();
              }
            }}
          />
          {validationMessage && <ErrorMessage message={validationMessage} />}
        </>
      );
    }
    return <></>;
  },
);

export default Inputs;
