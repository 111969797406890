import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import UserModel from "models/userModel";
import Spinner from "components/Spinner";
import GeneralModel from "models/generalModel";

function PrivateRoute({ component: RouteComponent }) {
  const userModel = useContext(UserModel.Context);
  const { errorHandler } = useContext(GeneralModel.Context);

  const [isLoading, setIsLoading] = useState(true);
  const [isAuthed, setIsAuthed] = useState(false);

  const getMe = async () => {
    try {
      const { _id } = await userModel.getMe();

      if (_id) {
        setIsAuthed(true);
        return true;
      }
      return false;
    } catch (error) {
      errorHandler(error);
      setIsAuthed(false);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  async function checkAuth() {
    await getMe();
  }

  useEffect(() => {
    checkAuth();
  }, []);

  if (isLoading) return <Spinner />;

  if (!isAuthed && !isLoading)
    return <Navigate to={{ pathname: "/" }} replace />;

  return <RouteComponent />;
}

export default PrivateRoute;
