import successIcon from "assets/icons/success.png";
import warningIcon from "assets/icons/warning.png";
import errorIcon from "assets/icons/error.png";
import infoIcon from "assets/icons/info.png";

function typeAttribute(type, theme, t) {
  switch (type) {
    case "error": {
      return {
        icon: errorIcon,
        color: theme.danger,
        title: "",
        confirmText: t("Button.confirm"),
        closeText: t("Button.back"),
        text: "",
      };
    }
    case "info": {
      return {
        icon: infoIcon,
        color: theme.info,
        title: "",
        confirmText: t("Button.confirm"),
        closeText: t("Button.back"),
        text: "",
      };
    }
    case "warning": {
      return {
        icon: warningIcon,
        color: theme.warning,
        title: t("Popup.areYouSure"),
        confirmText: t("Button.confirm"),
        closeText: t("Button.back"),
        text: "",
      };
    }
    case "unsaved": {
      return {
        icon: warningIcon,
        color: theme.warning,
        title: t("Popup.unsaved"),
        confirmText: t("Button.confirm"),
        closeText: t("Button.back"),
        text: t("Popup.lostDataWarning"),
      };
    }
    case "delete": {
      return {
        icon: warningIcon,
        color: theme.warning,
        title: t("Popup.confirmDelete"),
        confirmText: t("Button.confirm"),
        closeText: t("Button.back"),
        text: "",
      };
    }
    case "success":
    default: {
      return {
        icon: successIcon,
        color: theme.textSuccess,
        title: "",
        confirmText: t("Button.confirm"),
        closeText: t("Button.back"),
        text: "",
      };
    }
  }
}

export { typeAttribute };
