import React from "react";
import Alerts from "./Alert";
import Toasts from "./Toast";

function Popup() {
  return (
    <>
      <Alerts />
      <Toasts />
    </>
  );
}

export default Popup;
