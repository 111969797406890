import { useContext } from "react";
import BaseActions from "base/BaseActions";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "Configuration";

const useConfigurationModel = (savedModelInstance = {}) => {
  const { token, Organization } = useContext(UserModel.Context);

  const { getList, getOne, getSchema, createOne, updateOne, deleteOne } =
    BaseActions(module, { token, Organization });

  return {
    getList,
    getOne,
    getSchema,
    createOne,
    updateOne,
    deleteOne,
  };
};

const ConfigurationModel = BaseModel(
  useConfigurationModel,
  "ConfigurationModel",
);

export { useConfigurationModel };
export default ConfigurationModel;
