import { devices } from "./breakpoints";

const DEFAULT_THEME = {
  typography: {
    fontFamily: [
      "Noto Sans HK",
      "Noto Sans TC",
      " sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },

  // device size
  desktopSize: devices.desktop,
  tabletSize: devices.tablet,
  mobileSize: devices.mobile,
  miniMobileSize: devices.miniMobile,
  inputHeight: 42,

  primary: "#00A1B1",
  secondary: "#EEFCFF",
  warning: "#FFA800",
  danger: "#FA002C",
  gray: "#A3A3A3",
  success: "#3be347",
  info: "#2184E0",
  white: "#fff",
  black: "#000",

  // text color
  text: "#1D1D1D",
  textGray: "#B7B7B7",
  textSuccess: "#6AC3CC",

  // border color
  border: "#e0e0e0",
  borderGray: "#d9d9d9",

  boxShadow: "0px 0px 5px 0px #6BFFE480",

  good: "#00A1B1",
  slight: "#D8DB48",
  moderate: "#FFA800",
  severe: "#FA002C",
};

const ANT_THEME = {
  token: {
    fontFamily: DEFAULT_THEME.typography.fontFamily,
    fontSite: 14,
    colorPrimary: DEFAULT_THEME.primary,
    borderRadius: 10,
    colorBorder: DEFAULT_THEME.borderGray,
    colorError: DEFAULT_THEME.danger,
    controlHeight: DEFAULT_THEME.inputHeight,
    colorText: DEFAULT_THEME.text,
    colorBgSpotlight: DEFAULT_THEME.primary,
  },
  components: {
    Select: {
      optionSelectedColor: DEFAULT_THEME.primary,
    },
    TreeSelect: {
      nodeHoverBg: DEFAULT_THEME.secondary,
      nodeSelectedBg: DEFAULT_THEME.secondary,
    },
    Segmented: {
      itemSelectedBg: DEFAULT_THEME.primary,
      itemSelectedColor: DEFAULT_THEME.white,
      itemColor: DEFAULT_THEME.black,
      trackBg: DEFAULT_THEME.white,
    },
  },
};

export { ANT_THEME, DEFAULT_THEME };
