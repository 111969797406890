import React, { useContext } from "react";
import UserModel from "models/userModel";
import GeneralModel from "models/generalModel";

export default function DefaultRoute({ routes }) {
  const { isLoggedIn } = useContext(UserModel.Context);
  const { navigate } = useContext(GeneralModel.Context);

  const path = routes[0]?.path ? routes[0]?.path.replace("/*", "") : "";

  if (!isLoggedIn) {
    window.location.reload();
    return <></>;
  }
  navigate(path);
  return <></>;
}
