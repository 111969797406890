import { isEmpty } from "lodash";
import MENU_ITEMS from "constants/menu";

function getGrantedItems(AdminGroup, menuItem) {
  const { level, Permissions = [] } = AdminGroup || {};
  const { level: menuItemLevel = [], resources = [] } = menuItem || {};

  const isWithinAdminLevel = menuItemLevel.includes(level);

  if (resources.includes("*")) {
    return menuItem;
  }

  if (!isWithinAdminLevel) {
    return null;
  }

  const haveReadPermission = Permissions.find(
    (permission) =>
      resources.find((r) => r === permission.resource) &&
      permission.action.includes("read"),
  );

  if (!haveReadPermission) {
    return null;
  }

  return menuItem;
}

export function getGrantedMenuItems(AdminGroup = {}) {
  const { level } = AdminGroup;

  if (level === "system") {
    return MENU_ITEMS.map((e) => ({ ...e, actions: ["*"] }));
  }

  return MENU_ITEMS.reduce((acc, menuItem) => {
    const { children = [] } = menuItem || {};

    if (!children || children.length === 0) {
      const grantedItem = getGrantedItems(AdminGroup, menuItem);

      return grantedItem ? [...acc, grantedItem] : acc;
    }

    const filteredChildren = children.filter((childrenItem) => {
      const grantedItem = getGrantedItems(AdminGroup, childrenItem);

      return !!grantedItem;
    });

    if (filteredChildren.length > 0) {
      return [...acc, { ...menuItem, children: filteredChildren }];
    }

    return acc;
  }, []);
}

export function getResourceFromPath(location) {
  if (!location.pathname) {
    return "";
  }
  const splitString = location.pathname.split("/") || [];

  const objectIDIndex = splitString.findIndex((e) => {
    if (!e) {
      return false;
    }
    const regex = /^[A-F0-9]+$/gi;
    const isObjectID = e.length === 24 && regex.test(e);

    return isObjectID;
  });

  const isIncludeObjectID = objectIDIndex !== -1;

  const hash = location?.hash ? location?.hash.replace("#", "") : "";

  const path = isIncludeObjectID
    ? splitString[objectIDIndex - 1]
    : location.pathname.replace("/", "");

  if (hash) {
    return [hash, path];
  }

  return path;
}

export function getGrantedActions(permissions, resource) {
  if (Array.isArray(resource)) {
    const target = resource.reduce((acc, r) => {
      if (!isEmpty(acc)) {
        return acc;
      }

      const targetPermission = permissions.find(
        (permission) => permission.resource === r,
      );

      if (targetPermission) {
        return targetPermission;
      }

      return acc;
    }, {});

    return target?.actions;
  }

  const target = permissions.find((e) => e.resource === resource) || {};

  return target?.actions;
}
